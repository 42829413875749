define("discourse/plugins/discourse-mistflux-plugin/discourse/connectors/group-navigation", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupNavigation extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    setupComponent(outletArgs, component) {
      const url = window.location.toString();
      const categoryIdMatch = url.match(/category_id=(\d+)/);
      let category = null;
      if (categoryIdMatch) {
        const categoryId = parseInt(categoryIdMatch[1], 10);
        if (categoryId) {
          category = this.site.get('categories').findBy('id', categoryId);
        }
      }
      component.set('category', category);
    }
  }
  _exports.default = GroupNavigation;
});