define("discourse/plugins/discourse-mistflux-plugin/discourse/raw-templates/templates/connectors/topic-list-header-after-main-link/creator-column-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{raw "topic-list-header-column" name="creator"}}
  */
  {
    "id": "Bfl+VTTE",
    "block": "[[[1,[28,[35,0],[\"topic-list-header-column\"],[[\"name\"],[\"creator\"]]]]],[],false,[\"raw\"]]",
    "moduleName": "discourse/plugins/discourse-mistflux-plugin/discourse/raw-templates/templates/connectors/topic-list-header-after-main-link/creator-column-header.hbs",
    "isStrictMode": false
  });
});