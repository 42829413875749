define("discourse/plugins/discourse-mistflux-plugin/discourse/raw-templates/templates/connectors/topic-list-after-main-link/creator-column", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class="creator">
      <a href="{{get "topic.creator.path"}}" data-user-card="{{get "topic.creator.username"}}">
          {{#if context.topic.creator.name}}
              {{get "topic.creator.name"}}
          {{else}}
              {{format-username "topic.creator.username"}}
          {{/if}}
      </a>
  </td>
  */
  {
    "id": "oMUE+R3r",
    "block": "[[[10,\"td\"],[14,0,\"creator\"],[12],[1,\"\\n    \"],[10,3],[15,6,[29,[[28,[37,0],[\"topic.creator.path\"],null]]]],[15,\"data-user-card\",[29,[[28,[37,0],[\"topic.creator.username\"],null]]]],[12],[1,\"\\n\"],[41,[30,0,[\"context\",\"topic\",\"creator\",\"name\"]],[[[1,\"            \"],[1,[28,[35,0],[\"topic.creator.name\"],null]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,2],[\"topic.creator.username\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `context` property path was used in the `discourse/plugins/discourse-mistflux-plugin/discourse/raw-templates/templates/connectors/topic-list-after-main-link/creator-column.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.context}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"get\",\"if\",\"format-username\"]]",
    "moduleName": "discourse/plugins/discourse-mistflux-plugin/discourse/raw-templates/templates/connectors/topic-list-after-main-link/creator-column.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});