define("discourse/plugins/discourse-mistflux-plugin/discourse/components/m-categories-columned", ["exports", "@ember/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="m-categories-columned">
      {{#each this.columnedCategories as |c|}}
          {{#if this.displayMobileSubcategories}}
              <MMobileSubcategories @category={{c}} />
          {{else}}
              <MCategory @category={{c}} />
          {{/if}}
      {{/each}}
  </div>
  */
  {
    "id": "qVo7ntwH",
    "block": "[[[10,0],[14,0,\"m-categories-columned\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"columnedCategories\"]]],null]],null],null,[[[41,[30,0,[\"displayMobileSubcategories\"]],[[[1,\"            \"],[8,[39,3],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,4],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"]],[]]]],[1]],null],[13]],[\"c\"],false,[\"each\",\"-track-array\",\"if\",\"m-mobile-subcategories\",\"m-category\"]]",
    "moduleName": "discourse/plugins/discourse-mistflux-plugin/discourse/components/m-categories-columned.hbs",
    "isStrictMode": false
  });
  class MCategoriesColumned extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get columnedCategories() {
      return this.categories;
    }
    get displayMobileSubcategories() {
      if (this.site.mobileView) {
        let hasSubcategories = this.site.get("categories").some(c => {
          return (c.get("subcategories.length") || 0) >= 1;
        });
        if (hasSubcategories) {
          return true;
        }
      }
      return false;
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MCategoriesColumned);
});