define("discourse/plugins/discourse-mistflux-plugin/discourse/templates/connectors/before-topic-list-body/pinned-topics-tbody", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.pinnedTopics}}
      <tbody class="pinned-topics">
      {{#each this.pinnedTopics as |topic|}}
          <TopicListItem
                  @topic={{topic}}
                  @bulkSelectEnabled={{@outletArgs.bulkSelectEnabled}}
                  @lastVisitedTopic={{@outletArgs.lastVisitedTopic}}
                  @selected={{@outletArgs.selected}}
                  @hideCategory={{@outletArgs.hideCategory}}
                  @showPosters={{true}}
          />
      {{/each}}
      <tr class="table-break">
          <td colspan="10">&nbsp;</td>
      </tr>
      </tbody>
  {{/if}}
  */
  {
    "id": "g/VsC3AO",
    "block": "[[[41,[30,0,[\"pinnedTopics\"]],[[[1,\"    \"],[10,\"tbody\"],[14,0,\"pinned-topics\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"pinnedTopics\"]]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@topic\",\"@bulkSelectEnabled\",\"@lastVisitedTopic\",\"@selected\",\"@hideCategory\",\"@showPosters\"],[[30,1],[30,2,[\"bulkSelectEnabled\"]],[30,2,[\"lastVisitedTopic\"]],[30,2,[\"selected\"]],[30,2,[\"hideCategory\"]],true]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[10,\"tr\"],[14,0,\"table-break\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"10\"],[12],[1,\" \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"topic\",\"@outletArgs\"],false,[\"if\",\"each\",\"-track-array\",\"topic-list-item\"]]",
    "moduleName": "discourse/plugins/discourse-mistflux-plugin/discourse/templates/connectors/before-topic-list-body/pinned-topics-tbody.hbs",
    "isStrictMode": false
  });
});