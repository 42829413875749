define("discourse/plugins/discourse-mistflux-plugin/discourse/components/m-nav-breadcrumbs", ["exports", "@ember/component", "@ember/service", "discourse-common/lib/get-url", "@ember/template-factory"], function (_exports, _component, _service, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="m-breadcrumbs">
      <a href={{this.homeUrl}} class="m-breadcrumb m-breadcrumb-home">
          {{d-icon "mistflux-home"}}
          {{i18n "mistflux.forums"}}
      </a>
      {{#if this.category}}
          {{#if this.category.parentCategory}}
              <a href={{this.parentCategoryUrl}} class="m-breadcrumb m-breadcrumb-category">
                  {{d-icon "mistflux-chevron_right"}}
                  {{this.category.parentCategory.name}}
              </a>
          {{/if}}
          <a href={{this.categoryUrl}} class="m-breadcrumb m-breadcrumb-category">
              {{d-icon "mistflux-chevron_right"}}
              {{this.category.name}}
          </a>
      {{/if}}
      {{#if this.topicTitle}}
          <a href={{this.topicUrl}} class="m-breadcrumb m-breadcrumb-topic">
              {{d-icon "mistflux-chevron_right"}}
              {{html.safe this.topicTitle}}
          </a>
      {{/if}}
  </div>
  */
  {
    "id": "ul00kgj4",
    "block": "[[[10,0],[14,0,\"m-breadcrumbs\"],[12],[1,\"\\n    \"],[10,3],[15,6,[30,0,[\"homeUrl\"]]],[14,0,\"m-breadcrumb m-breadcrumb-home\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"mistflux-home\"],null]],[1,\"\\n        \"],[1,[28,[35,1],[\"mistflux.forums\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"category\"]],[[[41,[30,0,[\"category\",\"parentCategory\"]],[[[1,\"            \"],[10,3],[15,6,[30,0,[\"parentCategoryUrl\"]]],[14,0,\"m-breadcrumb m-breadcrumb-category\"],[12],[1,\"\\n                \"],[1,[28,[35,0],[\"mistflux-chevron_right\"],null]],[1,\"\\n                \"],[1,[30,0,[\"category\",\"parentCategory\",\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,3],[15,6,[30,0,[\"categoryUrl\"]]],[14,0,\"m-breadcrumb m-breadcrumb-category\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[\"mistflux-chevron_right\"],null]],[1,\"\\n            \"],[1,[30,0,[\"category\",\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"topicTitle\"]],[[[1,\"        \"],[10,3],[15,6,[30,0,[\"topicUrl\"]]],[14,0,\"m-breadcrumb m-breadcrumb-topic\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[\"mistflux-chevron_right\"],null]],[1,\"\\n            \"],[1,[28,[31,3,[\"safe\"]],[[30,0,[\"topicTitle\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"d-icon\",\"i18n\",\"if\",\"html\"]]",
    "moduleName": "discourse/plugins/discourse-mistflux-plugin/discourse/components/m-nav-breadcrumbs.hbs",
    "isStrictMode": false
  });
  class MNavBreadcrumbs extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    get category() {
      let currentCategory = null;
      if (this.router.currentRouteName.match(/^discovery\.[a-z]*category$/i)) {
        currentCategory = this.discovery.category;
      }
      if (this.outletArgs.topic) {
        return this.outletArgs.topic.get("category");
      } else if (currentCategory && !currentCategory.get("isUncategorized")) {
        return currentCategory;
      }
    }
    get homeUrl() {
      return (0, _getUrl.default)("/");
    }
    get topicTitle() {
      return this.outletArgs.topic?.get("fancyTitle") || "";
    }
    get topicUrl() {
      return this.outletArgs.topic?.get("url");
    }
    get categoryUrl() {
      return this.category?.get("url");
    }
    get parentCategoryUrl() {
      return this.category?.parentCategory?.get("url");
    }
  }
  _exports.default = MNavBreadcrumbs;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MNavBreadcrumbs);
});