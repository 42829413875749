define("discourse/plugins/discourse-mistflux-plugin/discourse/connectors/before-topic-list-body/pinned-topics-tbody", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PinnedTopicsBody = dt7948.c(class PinnedTopicsBody extends _component.default {
    get pinnedTopics() {
      return this.outletArgs.topics.filter(t => t.get("pinned"));
    }
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = PinnedTopicsBody;
});